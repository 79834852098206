export default [
  {
    name: "Counseling",
    description: "Conversationally explore a personal issue.",
    assistantId: "asst_7Xpj1Mp3eh20QOWtOe5Bg39x",
    conversationStarter: "How are you feeling today? Are you struggling with anything in particular?\n\nLet's discuss your situation and consider looking at it through the lens of analytic idealism."
  },
  {
    name: "Philosophy",
    description: "Best for high level discussions relating to idealism.",
    assistantId: "asst_NyNieQRB4T2UfNAcuDnfdQp3",
    conversationStarter: "How can I help you today? Are you struggling with anything in particular?\n\nLet's take a look at your situation through the lens of analytic idealism."
  },
  // {
  //   name: "Mentoring",
  //   description: "Struggling at work or having trouble making big decisions on your career path?",
  //   assistantId: "asst_QJlORVOintCpjf7OCgBIUcmG",
  //   conversationStarter: "I understand you're looking for some career advice? Are you struggling with something specific?\n\nI'm happy to share my career philosophy, perhaps it could be useful to take a look at your situation through the lens of analytic idealism."
  // },
  {
    name: "Work/Agile",
    description: "Frustrated at work? Having trouble making big decisions on your career path?",
    assistantId: "asst_7iCiSsuHEzo2q97DkHv2UtoF",
    conversationStarter: "I understand you're facing complications at work? Are you struggling with something specific?\n\nI'm happy to share my career philosophy. It may be that your situation can be helped by practicing Agile processes."
  },
  {
    name: "Socratic",
    facilitated: true,
    description: "Facilitates a difficult conversation between two people",
    assistantId: "asst_nMbEsAGg92iuABGmwhDJBCO9",
    conversationStarter: "I understand you'd like me to facilitate a discussion between you and someone close to you. Please describe the situation. What are you worried about or struggling with? Why are you finding it particularly difficult to discuss the topic with this person?"
  }
]