import React, {useState, useCallback, useEffect} from 'react';
import './Facilitated.css'
import http from './http';
import { getKey, saveKey } from './Storage';
import peopleSvg from './svg/users.svg'

function validateEmail(email) {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return regex.test(email)
}

export default ({props}) => {
  const {loadThread, setThreadMetadata} = props

  const [facilitatedOpen, setFacilitatedOpen] = useState(false)
  const [topic, setTopic] = useState('')//'Betrayal and recovery')
  const [myName, setMyName] = useState('')//'River')
  const [friendName, setFriendName] = useState('')//'Rain')
  const [friendEmail, setFriendEmail] = useState('')//'river.kanies@gmail.com')
  const [valid, setValid] = useState(false)

  const changeMyName = (e) => {// one word names
    const value = e.target.value.trim()
    setMyName(value)
  }
  const changeFriendName = (e) => {
    const value = e.target.value.trim()
    setFriendName(value)
  }

  useEffect(()=>{
    if (topic.length > 2 && topic.length < 255 && myName.length > 2 && friendName.length > 2 && validateEmail(friendEmail)) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [topic, myName, friendName, friendEmail])

  const create = useCallback(async function () {
    setFacilitatedOpen(false)
    const inputs = document.getElementById('create-facilitated-form').getElementsByTagName('input')
    // console.log('inputs', inputs)
    const data = {}
    Array.from(inputs).forEach(inp => (data[inp.name] = inp.value))
    // console.log('data', data)
    // create facilitated thread, set to current thread, close menu
    const res = await http.post('/debate', {debate: data, assistant_id: 'asst_nMbEsAGg92iuABGmwhDJBCO9'})
    console.log('res', res)
    // update threadMetadata
    const threadMetadataString = await getKey('threadMetadata')
    const threadMetadata = threadMetadataString ? JSON.parse(threadMetadataString) : []
    const newMetadata = [res.thread].concat(threadMetadata)
    console.log('new meta', newMetadata)
    saveKey('threadMetadata', JSON.stringify(newMetadata))
    setThreadMetadata(newMetadata)
    // select thread
    setTimeout(()=>loadThread(res.thread.thread_id)(), 100)
  },[])

  return <>
    <button className='open-facilitated' onClick={()=>setFacilitatedOpen(!facilitatedOpen)}>+ Invite friend to conversation</button>
    {facilitatedOpen && <div className='form-facilitated' id='create-facilitated-form'>
      <div className='form-facilitated-header'>
        <img src={peopleSvg} />
        Start a conversation with another person, facilitated by the CyberMonk. The CyberMonk will take turns talking to each of you, using the Socratic method to tease out details about the situation from both people's perspectives.
      </div>
      <input name='topic' placeholder='Topic...' onChange={(e)=>setTopic(e.target.value)} defaultValue={topic}/>
      <input name='initiator_name' placeholder='MyName' onChange={changeMyName} value={myName} />
      <input name='receiver_name' placeholder='FriendName' onChange={changeFriendName} value={friendName} />
      <input name='receiver_email' placeholder='friend@email.ex' onChange={(e)=>setFriendEmail(e.target.value)} defaultValue={friendEmail} type='email'/>
      <button className='create-facilitated' disabled={!valid} onClick={create}>Create and Invite</button>
    </div>}
  </>
}