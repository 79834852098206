
export default ({scrollToEx, aboutRefs}) => {
  const { aboutRef, safetyInfoRef, convoInfoRef } = aboutRefs
  return <>
    <div ref={aboutRef} className='examples-header'>About the CyberMonk</div>
    <p>The CyberMonk is an AI life coaching chatbot, designed to analyze scenarios from the perspective of metaphysical idealism.</p>
    <br/>
    <b>Usage Tips:</b>
    <ol>
      <li>Don't underestimate the CyberMonk system. It is very much capable of contending with complex and abstract concepts.</li>
      <li>Give details, send a multi-sentence or multi-paragraph message outlining specifics of your situation (dictation is nice here, look for a microphone icon on your device keyboard).</li>
      <li>Ask about something you truly are struggling with. The power of the system shows best when it can provide a hint of insight into a real-life problem.</li>
      <li>If the bot suggests you seek help from a professional, you may need to <span onClick={()=>safetyInfoRef.current?.scrollIntoView({ behavior: "smooth" })
  } className="view-examples">reword the question</span> to get a useful answer. Ex: if you tell the bot that you are depressed it may assume you're a danger to yourself and default to a <span onClick={()=>safetyInfoRef.current?.scrollIntoView({ behavior: "smooth" })
  } className="view-examples">safe response</span>.</li>
    </ol>
    <p>For those unsure about what to ask the CyberMonk, see the <span onClick={scrollToEx} className="view-examples">list</span> of example conversations to get a sense for what the CyberMonk is capable of.</p>
    <p>The CyberMonk can be thought of as a simulacrum of its developer (River Kanies), and to employ principles outlined in his <a className="view-examples" href="https://open.spotify.com/show/6jwZYOYh6r0vFNvSzE2jO4" target="_blank">podcast</a> and essays (the image of the CyberMonk is also meant to resemble him).</p>
    <p>Users can expect to have more chatbots with a variety of specialized use-cases made available in the near future. If you're interested in having a chatbot/simulacrum made (of yourself or otherwise), get in <a className="view-examples" href="https://www.linkedin.com/in/river-kanies-95706389/" target='_blank'>contact</a>!</p>

    <br/><br/>

    <div ref={convoInfoRef} className='examples-header'>Conversation Tips</div>
    <p>Thy CyberMonk has a limited attention span. Every time you ask a question, the entire conversation (including all previous responses) is sent back to the CyberMonk to generate the next response. Here are some tips to get the most out of a conversation:</p>
    <ol>
      <li>Create a new conversation (using the '+' button) whenever you want to discuss a new topic. This helps the system stay focussed on the topic of interest.</li>
      <li>Keep each conversation to less than 10 questions. After that the system context may get maxed out (in which case it will stop responding).</li>
      <li>If you want to continue a long conversation, start a new one and summarize the previous one.</li>
    </ol>

    <br/><br/>

    <div ref={safetyInfoRef} className='examples-header'>Safety Info</div>
    <p>The CyberMonk is not a mental health professional or a replacement for one. If the system thinks you may be a danger to yourself it will prompt you to seek help rather than try to respond directly to your question. This commonly happens when depression or suicide are brought up (for example). However, the CyberMonk is very much capable of discussing such topics. To avoid the system thinking you are a danger to yourself, phrase the question as impersonal or hypothetical.</p>
    <br/>
    <b>Will Trigger Safe Response:</b>
    <ol>
      <li>"I feel depressed often. Like nothing is fun and everything is pointless."</li>
      <li>"Sometimes I think it would be easier to end it all."</li>
    </ol>
    <b>Will Respond With Useful Info:</b>
    <ol>
      <li>"If someone felt depressed, what should be brought to their attention?"</li>
      <li>"How could someone want to end their own life? Why is that even possible?"</li>
    </ol>
    <p>*Note: if a safe response is triggered you'll usually need to start a new conversation to get a useful response on the topic.</p>
    <br/><br/><br/><br/><br/>
  </>
}