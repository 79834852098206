import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {initDb} from './Storage';

import { SplashScreen } from '@capacitor/splash-screen';
import * as LiveUpdates from '@capacitor/live-updates';
import * as AppEvents from '@capacitor/app'
import inAppPurchase from './inAppPurchase.js';

//init
window.cybermonk = {
  db: null
}

async function checkForUpdate () {
  const result = await LiveUpdates.sync();
  if (result.activeApplicationPathChanged) {
    await LiveUpdates.reload();
  }
  else {
    await SplashScreen.hide();// android only
  }
}

function loadPaypal () {
  var script = document.createElement('script');
  script.onload = function () {
      //do stuff with the script
      console.log('paypal loaded')
  };
  script.src = "https://www.paypal.com/sdk/js?client-id=AXA9OFAtAb87Ja1J9tER_cbMi2P4GjqRUNPbumUgh_aiwbBXzft-1eRdx_jeKkCJ_lQD0tVY5Kr9uIL8&currency=USD"
  //sandbox
  //script.src = "https://www.paypal.com/sdk/js?client-id=Adg9cb_A55K12WVt3uKvaaFWd9L7H8Qcfw_UvliXb05cJQEdIh4SRJO1GAEP6wU-Hy-yYEubSNuJEnyh&currency=USD"

  document.head.appendChild(script);
}

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  //StrictMode causes useEffect to trigger twice https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    initDb()
    renderReactDom();
    checkForUpdate()
    AppEvents.App.addListener('resume', () => {
      console.log('resuming, check for update')
      checkForUpdate()
    })
    inAppPurchase.init()
  }, false);
} else {
  loadPaypal()
  renderReactDom();
}
