// for dev testing
    // window.cybermonk.db.executeSql('DROP TABLE Keys')
    // localStorage.clear()

import http from './http'

function executeSqlAsync(query, params) {
    return new Promise((resolve, reject) => {
        window.cybermonk.db.executeSql(query, params, 
            (resultSet) => {
                // The operation was successful, resolve the promise
                resolve(resultSet);
            },
            (error) => {
                // There was an error, reject the promise
                reject(error);
            }
        );
    });
}

function initDb () {
    if (!window.cordova) return console.log('not cordova')

    // Database to persist auth token
    var db = window.sqlitePlugin.openDatabase({
        name: 'my.db',
        location: 'default',
    });
    window.cybermonk.db = db

    db.executeSql('CREATE TABLE IF NOT EXISTS Keys (name, value)');
}

function dropKeys () {
    if (window.cordova) {
        window.cybermonk.db.executeSql('DROP TABLE Keys')
    } else {
        localStorage.clear()
    }
}

//   function setValueForKey (key, set) {
//     return () => {
//         if (!window.cordova) {
//             console.log('not cordova')
//             set(localStorage.getItem(key))
//         } else {
//             console.log("getting value for ", key)
//             window.cybermonk.db.executeSql('SELECT * FROM Keys WHERE name=?', [key], function (resultSet) {
//                 console.log('Sample column value: ' + resultSet.rows.item(0).value);
//                 set(resultSet.rows.item(0).value)
//             }, function(error) {
//                 console.log('SELECT SQL statement ERROR: ' + error.message);
//             });
//         }
//     }
//   }

  // Usage with async/await
async function getKey(key) {
    if (!window.cordova) {
        // console.log('not cordova')
        let value = localStorage.getItem(key)
        if (value == '') value = null
        return value
    } else {
        try {
            const resultSet = await executeSqlAsync('SELECT * FROM Keys WHERE name=?', [key]);
            console.log('Sample column value: ' + resultSet.rows.item(0).value);
            return resultSet.rows.item(0).value;
        } catch (error) {
            console.log('SELECT SQL statement ERROR: ' + error.message);
            // Handle the error appropriately
        }
    }
}
//localStorage.setItem('currentThreadId', 'thread_KewytWhKsWFgULz0M6NBVJfI')

  async function saveKey (key, value) {// refactor to use executeSqlAsync
    if (!window.cordova) {
        // console.log('not cordova, save key', key, value)
        let val = value
        if (!value) val = ''
        localStorage.setItem(key, val)
    } else {
        console.log('save key', key, value)
        const resultSet = await executeSqlAsync('SELECT * FROM Keys WHERE name=?', [key])
        if (resultSet.rows.item(0)?.value) {
            console.log('updating')
            const res = await executeSqlAsync("UPDATE Keys SET value = ? WHERE name = ?", [value, key])
            console.log("updated: ", res)
        } else {
            console.log('inserting')
            const res = await executeSqlAsync('INSERT INTO Keys VALUES (?,?)', [key, value]);
            console.log('inserted: ', res)
        }
    }
  }

  function testSignin (set) {
    if (process.env.REACT_APP_ENV == 'prod') return console.error('cannot sign in on prod with test user')
    const appleInfo = {mode: 'dev'}
    saveKey("appleAuthObj", JSON.stringify(appleInfo))
    set(appleInfo)
  }

  function appleSignin (set) {
    window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        function(appleInfo){
            console.log(appleInfo)
            //   alert(JSON.stringify(appleInfo))
            saveKey("appleAuthObj", JSON.stringify(appleInfo))
            set(appleInfo)
        },
        function(err){
            console.error(err)
            console.log(JSON.stringify(err))
        }
    )
    
  }


// *** refactor to use new getKey async function
  async function sessionStart () {
    const appleInfo = await getKey("appleAuthObj")
    const {sessionToken, userRecord} = await http.post("/sessions", JSON.parse(appleInfo))
    console.log('session start', sessionToken, userRecord)
    await saveKey("jwt", sessionToken)
    await saveKey("user", JSON.stringify(userRecord))
  }


  export {
    initDb,
    dropKeys,
    saveKey,
    appleSignin,
    testSignin,
    // setValueForKey,
    sessionStart,
    getKey
  }

  /* apple signin responses

when device isn't logged into apple:
  {"code":"1000","localizedFailureReason":"","error":"ASAUTHORIZATION_ERROR","localizedDescription":"The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1000.)"}

success:
{email: "river.kanies@gmail.com", authorizationCode: "cc0c388d4484d4851bc70b9a056a80c8e.0.rrzzz.0eMx5uSO-ZUGmkjUIRTSmw", state: "", identityToken: "eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiO…6uoE7ZP6BmPZFZt7saVjFMaSQYldXUgFdtIF0fE7ESsggIYLw", fullName: Object, …}

success after 1st login no email:
{email: "", authorizationCode: "c3a4cf552fb7f4ee1bef462e600072f48.0.rrzzz.DPSapoHjiEzwGuDWatWpuA", state: "", identityToken: "eyJraWQiOiJXNldjT0tCIiwiYWxnIjoiUlMyNTYifQ.eyJpc3M…wMQ5hpgbHTJe_VK8lWc0Lx_-dVs0O4bJ1GMSRB_kGyL1NSQtg", fullName: Object, …}

Notes: identityToken is super long and this is not the full one

  */