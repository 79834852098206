import React, { useState, useCallback, useRef } from 'react';
import './MessageInput.css'
import sendArrow from './svg/arrow-send.svg'
import { saveKey } from './Storage';
import { getKey } from './Storage';
import http from './http'
import Coin from './decor/Coin.js'


const MessageInput = ({props}) => {
  const {messages, setMessages,
      waiting, setWaiting,
      assistantPrompt,
      threadId, setThreadId,
      setThreadMetadata,
      setShouldRefresh,
      user, setUser,
      openMenu,
      topic,
      showConvoInfo,
      currentThread,
      theirName,
      loadThread
    } = props

  const isDebate = currentThread?.is_debate
  const warnAtLength = topic.facilitated ? 30 : 14

  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false)
  const [sendOptionsOpen, setSendOptionsOpen] = useState(false)//false
  const inputRef = useRef()

  const showSendOptions = () => {
    setSendOptionsOpen(true)
  }

  const checkCredit = useCallback((event) => {
    setIsFocused(true)
    setSendOptionsOpen(false)
    console.log('focus', process.env.NODE_ENV, process.env.REACT_APP_ENV)
    if (!user || user.credit_count < 1) {
      console.log('no cred')
      event.target.blur()
      openMenu()
    } else {
      console.log('has credit', user.credit_count)
    }
  }, [user])

  const unfocus = () => {
    setTimeout(()=>{
      setIsFocused(false)
    }, 10)
  }

  const handleChange = useCallback((event) => {
    const textarea = event.target;
    resizeTextarea(textarea)
    setMessage(event.target.value);
  }, [])

  const resizeTextarea = useCallback((textarea) => {
    // Reset the height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set the height to the scrollHeight to fit all content
    textarea.style.height = `${textarea.scrollHeight-20}px`;
  }, [])

  const handleSend = useCallback(async function () {
    // Handle the message send action here
    const message = inputRef.current?.value
    console.log('Message sent:', message);

    setMessage(''); // Clear the input after send
    setSendOptionsOpen(false)
    setTimeout(() => {// and resize to 1 line
      const textarea = inputRef.current
      resizeTextarea(textarea)
    }, 1)

    const newMessage = {role: 'user', content: message}
    const currentMessages = [...messages, newMessage]
    setMessages(currentMessages)
    // console.log('msgs', messages)

    setWaiting(true)

    // assume deduct credit
    setUser({...user, credit_count: (user?.credit_count - 1 || 0)})// triggers coin animation

    let threadRes = null
    let shouldSaveThreadMetadata = false

    try {
      if (threadId) {
        console.log('calling update', threadId)
        threadRes = await http.put(`/ai/${threadId}`, {message: newMessage})
      } else {
        shouldSaveThreadMetadata = true
        console.log('calling create', threadId)
        threadRes = await http.post('/ai', {message: newMessage, assistantId: topic.assistantId})
      }
      console.log('thread', threadRes)
    } catch (err) {
      setShouldRefresh(true)
      return console.log('timeout', err)
    }

    const {deductedUser} = threadRes
    await saveKey("user", JSON.stringify(deductedUser))
    setUser(deductedUser)

    let thread_id = null
    let assistant_id = null
    if (threadRes.status) {// handle timeout
      thread_id = threadRes.thread_id
      saveKey('currentThreadId', thread_id)
      setShouldRefresh(true)
      console.log('timeout', threadRes)
    } else {
      const {thread} = threadRes
      thread_id = thread.data[0].thread_id
      const newMessages = thread.data.map(d => {
        const {role, created_at} = d
        const content = d.content[0].text.value
        return {role, created_at, content}
      })
      assistant_id = thread.data.find(m=>m.assistant_id)?.assistant_id

      setMessages([assistantPrompt].concat(newMessages))
      setThreadId(thread_id)// don't fetch thread
      saveKey('currentThreadId', thread_id)
      setWaiting(false)
    }

    if (!thread_id) return console.error('no thread id! req failed')

    if (shouldSaveThreadMetadata) {//created new thread: save new metadata
      const meta = {
        thread_id,
        name: message.slice(0, 100),
        assistant_id
      }
      const threadMetadataString = await getKey('threadMetadata')
      const threadMetadata = threadMetadataString ? JSON.parse(threadMetadataString) : []
      const newMetadata = [meta].concat(threadMetadata)
      console.log('new meta', newMetadata)
      saveKey('threadMetadata', JSON.stringify(newMetadata))
      setThreadMetadata(newMetadata)
    } else {// updated existing thread: re-order metadata
      const threadMetadataString = await getKey('threadMetadata')
      let threadMetadata = threadMetadataString ? JSON.parse(threadMetadataString) : []
      const moveIndex = threadMetadata.map(tm => tm.thread_id).indexOf(thread_id)
      if (moveIndex < 0) return console.error('no meta')
      const [meta] = threadMetadata.splice(moveIndex, 1)// modifies threadMetadata
      console.log('spliced', meta, threadMetadata)
      const newMetadata = [meta].concat(threadMetadata)
      console.log('new meta', newMetadata)
      saveKey('threadMetadata', JSON.stringify(newMetadata))
    }
  }, [messages, user, topic])

  const handlePass = useCallback(async function () {
    const message = inputRef.current?.value
    console.log('Pass with msg:', message);

    setMessage(''); // Clear the input after send
    setSendOptionsOpen(false)
    setTimeout(() => {// and resize to 1 line
      const textarea = inputRef.current
      resizeTextarea(textarea)
    }, 1)

    //call pass
    const res = await http.put(`/debate/${currentThread.thread_id}`, {message})
    console.log('pass res', res.debate)
    //receive new metadata (save and set)
    const threadMetadataString = await getKey('threadMetadata')
    let threadMetadata = threadMetadataString ? JSON.parse(threadMetadataString) : []
    const moveIndex = threadMetadata.map(tm => tm.thread_id).indexOf(currentThread.thread_id)
    if (moveIndex < 0) return console.error('no meta')
    const [meta] = threadMetadata.splice(moveIndex, 1)// modifies threadMetadata
    console.log('spliced', meta, threadMetadata)
    const metaWithUpdatedDebate = {...meta, ...res.debate}
    const newMetadata = [metaWithUpdatedDebate].concat(threadMetadata)
    console.log('new meta', newMetadata)
    saveKey('threadMetadata', JSON.stringify(newMetadata))
    setThreadMetadata(newMetadata)
    //load new thread => just reload page
    setTimeout(()=>loadThread(res.debate.thread_id)(), 100)
  },[currentThread])

  return (
    <div className='message-container'>
      <div className='message-container-inner'>
        <textarea
          ref={inputRef}
          className='message-textarea'
          value={message}
          onChange={handleChange}
          onFocus={checkCredit}
          onBlur={unfocus}
          placeholder="I'm stressed about..."
          rows={1} // Initial row size, it will expand automatically
        />
        <button className='send-button'
          onClick={isDebate ? showSendOptions : handleSend}
          disabled={waiting || message == ''}
        >
          <img src={sendArrow} alt="send" />
          <div className={`send-cost-indicator ${waiting ? "send-cost-indicator-hide" : (message != '' && !isDebate && "send-cost-indicator-visible")}`}><b>-1</b><Coin key={user?.credit_count || 0}/></div>
        </button>
        {isFocused && messages.length > warnAtLength && <div className="convo-length-warning">WARNING: Nearing conversation length limit! <span onClick={showConvoInfo}>{"View details"}</span></div>}
        {sendOptionsOpen && <div className='send-options'>
            <button className='send-option' onClick={handleSend}>
              <b>Send:</b> continue talking with the CyberMonk.
              <span>-1 <Coin/></span>
            </button>
            <button className='send-option' onClick={handlePass}>
              <b>Pass:</b> pass conversation to {theirName}.
              <span>Free</span>
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default MessageInput;


/*
what is idealism in one sentence?
what is materialism in one sentence?
*/

/* manually set credits for dev on web
let u = localStorage.getItem("user"); localStorage.setItem("user", JSON.stringify({...JSON.parse(u), credit_count: 0}))
*/