import './ProcessingPurchaseOverlay.css'
import './MessageList.css'// for animated elipsis


export default ({isProcessingPurchase}) => {
  return <>
    {isProcessingPurchase && <>
      <div className="processing-purchase-overlay"></div>
      <div className="processing-purchase-indicator">

        <div className='processing-purchase-header'>
          Processing Purchase
        </div>

        <div className="ellipsis elipsis-processing-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      
      </div>
    </>}
  </>
}