export default {
  init: () => {
    window.inAppPurchase
      .getProducts(['cybermonk5powerCoins1dollar'])// io.cordova.cybermonk.cybermonk5powerCoins1dollar
      .then(function (products) {
        window.cybermonk.products = products
        console.log('products', products);
        /*
          [{ productId: 'com.yourapp.prod1', 'title': '...', description: '...', currency: '...', price: '...', priceAsDecimal: '...' }, ...]
        */
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  buy: (cb, cancel) => {
    window.inAppPurchase
      .buy('cybermonk5powerCoins1dollar')
      .then(cb)
      .catch(cancel);
  }
}