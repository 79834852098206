import React, {useState, useRef, useCallback, useEffect} from 'react';

import './Menu.css'
import http from './http.js'
import CoinCount from './decor/CoinCount.js'
import Coin from './decor/Coin.js'
import dollarSvg from './svg/coin-dollar.svg'
import logoutSvg from './svg/exit.svg'
import threadSvg from './svg/bubbles4.svg'
import goSvg from './svg/go.svg'
import About from './About.js'
import inAppPurchase from './inAppPurchase.js';
import { saveKey } from './Storage.js';
import ProcessingPurchaseOverlay from './ProcessingPurchaseOverlay.js';
import Facilitated from './Facilitated.js';
import peopleSvg from './svg/users.svg'

const iOS = process.env.REACT_APP_DEVICE == "ios"

export default ({props}) => {
  const {isMenuOpen, closeMenu, isListOpen, openList, closeList, threadMetadata, setThreadMetadata, threadExampleMetadata, threadId, loadThread, user, setUser, logout, aboutRefs} = props

  const examplesRef = useRef()
  const [isProcessingPurchase, setIsProcessingPurchase] = useState(false)

  const price = (window.cybermonk.products || []).find(p => p.productId == 'cybermonk5powerCoins1dollar')?.priceAsDecimal || 1

  const credit = user?.credit_count || 0
  const email = user?.email || ''

  function handleLogout() {
    if (!window.confirm("Are you sure you want to log out?")) return
    logout()
  }

  function viewExamples() {
    closeMenu()
    openList()
  }

  function scrollToEx() {
    examplesRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const copyEmail = useCallback(async function () {
    await window.navigator.clipboard.writeText(user?.email || '')
    window.alert("Copied Email")
  }, [user])

  // purchase actions
  async function verifyPurchase(receipt) {
    const updatedUser = await http.post('/receipt', receipt)
    console.log('verified', updatedUser)
    if (!updatedUser || !updatedUser.email || updatedUser.email != user.email) return
    setUser(updatedUser)
    saveKey("user", JSON.stringify(updatedUser))
    setIsProcessingPurchase(false)
  }
  function upCred () {
    // if (process.env.REACT_APP_ENV == 'prod') return console.error('beta version: cant buy on prod')
    //http.put("/credits")//only updates on frontend after logout/login
    inAppPurchase.buy(verifyPurchase, function (err) {
      console.log('cancelled purchase', err);
      setIsProcessingPurchase(false)
    })
    setIsProcessingPurchase(true)
  }

  function deleteAccount () {
    if (!window.confirm('WARNING: Are you sure you want to delete this account? You will not be able to access conversations on this account after it is deleted')) return
    http.del('/user')
    logout()
  }

  return <>
    <ProcessingPurchaseOverlay isProcessingPurchase={isProcessingPurchase} />

    <div className={`menu-overlay ${isMenuOpen ? 'active' : ''}`} onClick={closeMenu}></div>
    <div className={`menu ${isMenuOpen ? 'active' : ''}`}>

      <div className='menu-header'><CoinCount count={credit}/></div>

      <div className='menu-body'>
        {credit == 0 && <div className='menu-warning'>Out of Power!</div>}
        {iOS &&
          <div className='buy-section'>
            <span><b>Buy Power</b></span>
            <button onClick={upCred} className='buy-button'>
              <span><b>{price}</b><img src={dollarSvg} alt="dollar" /></span>
              <b>=</b>
              <span><b>5</b><Coin/></span>
            </button>
          </div>
        }
        {!iOS &&
          <div className={`beta-section`}><br/>
            <span><b>Buy Power</b></span><br/>
            <div className='buy-button buy-label' disabled>
              <span><b>5</b><img src={dollarSvg} alt="dollar" /></span>
              <b>=</b>
              <span><b>30</b><Coin/></span>
            </div>
            <br/><br/>
            <div id="paypal-button-container"></div>
            <p id="result-message"></p>
          </div>
        }
        {!iOS && <div className='beta-section'>
            <span><b>Request Power</b></span>
            <a className="beta-link" href="https://www.linkedin.com/in/river-kanies-95706389/" target='_blank'>
              Contact Developer
              <img src={goSvg} alt="go" />
            </a>
            <p>{"Send a message to the developer requesting some Power Coins\n(include the email you used to login)"}</p>
          </div>
        }
        <div className='beta-section'>
          <div className='beta-flag'>Free</div>
          <span><b>View Examples</b></span>
          <button className="beta-link free-button" onClick={viewExamples}>
            Example Conversations
            <img src={goSvg} alt="go" />
          </button>
          <p>{"View some saved conversations to get a sense for the CyberMonk's capabilities"}</p>
        </div>
      </div>

      <div className='menu-footer'>
        <div className="policies-menu">
          <a href="https://drive.google.com/file/d/1NeMIH0wCGva7GXgM8IIkx_YEau9rp-pk/view?usp=drive_link" target='_blank'>Terms</a>
          and
          <a href="https://drive.google.com/file/d/1DUTS3ubROYAGxwief5hEG1noRGtkGUwd/view?usp=sharing" target='_blank'>Privacy</a>
        </div>
        <button onClick={handleLogout} className='logout-button'><img src={logoutSvg} alt="logout" /></button>
        <div className='menu-email' onClick={copyEmail}>{email}</div>
      </div>

    </div>


    <div className={`list-overlay ${isListOpen ? 'active' : ''}`} onClick={closeList}></div>
    <div className={`list ${isListOpen ? 'active' : ''}`}>
      <h3 className='list-header'>Conversations <img src={threadSvg} alt="threads" /></h3>
      <div className='list-body'>
      <Facilitated props={{loadThread, setThreadMetadata}}/>
      <div className='examples-header'>Recent Conversations</div>
        {threadMetadata.length > 0 ? threadMetadata.map((tm, i) =>
            <button key={i} className={`list-item ${tm.is_debate && 'list-item-debate'}`}
              onClick={loadThread(tm.thread_id)}
              disabled={tm.thread_id == threadId}
            >
              {tm.name}
              {tm.is_debate && <div className='debate-indicator'><img src={peopleSvg} /></div>}
            </button>
          )
        :
          <button className='list-item' disabled>(None yet)</button>
        }
        <div ref={examplesRef} className='examples-header'>Example Conversations</div>
        {threadExampleMetadata.map((tm, i) =>
          <button key={i} className="list-item"
            onClick={loadThread(tm.thread_id, true)}
            disabled={tm.thread_id == threadId}
          >
            {tm.name}
          </button>
        )}
        <About scrollToEx={scrollToEx} aboutRefs={aboutRefs} />
        <button onClick={deleteAccount} className='delete-account-button'>Delete Account</button>
      </div>
    </div>

  </>
}