import {getKey} from './Storage'

function apiBaseUrl () {
  console.log('env', process.env.REACT_APP_ENV)
  // if (!window.location
  //   || window.location.hostname == "localhost"
  //   || window.location.hostname === "127.0.0.1"
  //   || window.location.pathname.slice(0,6) == "/Users"
  if (process.env.REACT_APP_ENV == "prod") {
    return 'https://cybermonk.art'
  } else {
    if (process.env.REACT_APP_DEVICE == "web") {
      return "http://localhost:4000"
    } else {
      return "https://d8fa36b9dd46.ngrok.app"// real device testing
    }
  }
}

async function getHeaders () {
  const jwt = await getKey("jwt")
  return {
      'Content-Type': 'application/json',
      'Authorization': jwt
  }
}


async function get (path) {
  const headers = await getHeaders()
  const raw = await fetch(apiBaseUrl()+path, {headers: headers})
  const res = await raw.json()
  return res
}

async function post (path, input) {
  const headers = await getHeaders()
  const raw = await fetch(apiBaseUrl()+path, {
      method: 'POST',
      body: JSON.stringify(input), 
      headers: headers
  })
  const res = await raw.json()
  return res
}

async function put (path, input) {
  const headers = await getHeaders()
  const raw = await fetch(apiBaseUrl()+path, {
      method: 'PUT',
      body: JSON.stringify(input), 
      headers: headers
  })
  const res = await raw.json()
  return res
}

async function del (path) {
  const headers = await getHeaders()
  const raw = await fetch(apiBaseUrl()+path, {
      method: 'DELETE',
      headers: headers
  })
  const res = await raw.json()
  return res
}

export default {
  get,
  post,
  put,
  del
}