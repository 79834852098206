import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import settingsSvg from './svg/custom-settings.svg'
import stackSvg from './svg/stack.svg'
import plusSvg from './svg/plus.svg'
import './App.css';
import {saveKey, getKey, appleSignin, testSignin, sessionStart, dropKeys} from './Storage.js'
import MessageInput from './MessageInput.js';
import MessageList from './MessageList.js';
import http from './http.js'
import Menus from './Menu.js'
import Login from './Login.js'
import CoinCount from './decor/CoinCount.js';
import { Network } from '@capacitor/network';
import OfflineOverlay from './OfflineOverlay.js';
import topics from './topics.js'
import goSvg from './svg/go.svg'
import lockSvg from './svg/lock.svg'
import modeSvg from './svg/lab.svg'
import initPaypal from './paypal.js'

const isWeb = process.env.REACT_APP_DEVICE == "web"

const assistantPrompt = {role: 'assistant', content: topics[0].conversationStarter}

function logout () {
  dropKeys()
  setTimeout(() => window.location.reload(), 300)
}

function App() {
  const [initialized, setInitialized] = useState(false)
  const [auth, setAuth] = useState(null)
  const [appleInfo, setAppleInfo] = useState(null)
  const [messages, setMessages] = useState([assistantPrompt]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [waiting, setWaiting] = useState(false)//for response from bot
  const [threadId, setThreadId] = useState(null)//'thread_CfLIJAA6y7ZfWEltauzmkJc3')
  const [threadMetadata, setThreadMetadata] = useState([])
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const [user, setUser] = useState(null)
  const [threadExampleMetadata, setThreadExampleMetadata] = useState([])
  const [isExample, setIsExample] = useState(false)
  const [isOffline, setIsOffline] = useState(false)
  const [topic, setTopic] = useState(topics[0])
  const [topicListOpen, setTopicListOpen] = useState(false)

  const aboutRef = useRef()
  const convoInfoRef = useRef()
  const safetyInfoRef = useRef()
  const aboutRefs = {aboutRef, convoInfoRef, safetyInfoRef}

  const topicLocked = threadId || waiting

  const currentThread = useMemo(()=>{
    const thread = threadMetadata.find(tm => (tm.thread_id == threadId))
    return thread
  },[threadId, threadMetadata])

  const [myTurn, theirName] = useMemo(()=>{
    if (!currentThread || !currentThread.is_debate) return [true, null]
    const amInitiator = currentThread.initiator_email == user.email
    const isMyTurn = amInitiator ? currentThread.initiators_turn : !currentThread.initiators_turn
    const othersName = amInitiator ? currentThread.receiver_name : currentThread.initiator_name
    return [isMyTurn, othersName]
  },[currentThread, user])

  // *** MENU ACTIONS

  const openMenu = () => {
    // console.log('env', process.env)
    setIsMenuOpen(true);
    //check for credit update
    async function checkCredits () {
      const {credit_count} = await http.get('/credits')
      if (credit_count != user.credit_count) { //update user data
        const updatedUser = {...user, credit_count}
        setUser(updatedUser)
        saveKey("user", JSON.stringify(updatedUser))
      }
    }
    checkCredits()
    initPaypal(setUser)
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const openList = () => {
    async function loadMeta () {
      const threadMetadataString = await getKey('threadMetadata')
      const tm = threadMetadataString ? JSON.parse(threadMetadataString) : []
      setThreadMetadata(tm)
    }
    loadMeta()
    setIsListOpen(true);
  };
  const closeList = () => {
    setIsListOpen(false);
  };

  const showAbout = () => {
    setIsListOpen(true)
    setTimeout(() => {
      aboutRef.current?.scrollIntoView({ behavior: "smooth" })
    }, 300)
  }
  const showConvoInfo = () => {
    setIsListOpen(true)
    setTimeout(() => {
      convoInfoRef.current?.scrollIntoView({ behavior: "smooth" })
    }, 300)
  }
  const showSafetyInfo = () => {
    setIsListOpen(true)
    setTimeout(() => {
      safetyInfoRef.current?.scrollIntoView({ behavior: "smooth" })
    }, 300)
  }

  const selectTopic = (i) => {
    return () => {
      setTopic(topics[i])
      setTopicListOpen(false)
    }
  }

  // *** ACTIONS

  const login = useCallback(() => {
    appleSignin(setAppleInfo)
  })
  const loginTest = useCallback(() => {
    testSignin(setAppleInfo)
  })

  const startNewConversation = useCallback(() => {
    console.log('new convo')
    setThreadId(null)
    saveKey('currentThreadId', null)
    setWaiting(false)
    setIsExample(false)
    setMessages([])// clear to animate prompt
    setTimeout(() => setMessages([assistantPrompt]), 200)//set propmt, animate in
    if (topic.facilitated) setTopic(topics[0])
  }, [topic])

  const fetchThread = useCallback((thread_id) => {
    if (!thread_id) return
    async function call () {//refactor into a globally accessible function?
      // console.log('fetching thread', thread_id)
      setWaiting(true)
      const thread = await http.get(`/ai/${thread_id}`)
      const newMessages = thread.data.map(d => {
        const {role, created_at} = d
        const content = d.content[0].text.value
        return {role, created_at, content}
      })
      setMessages([assistantPrompt].concat(newMessages))
      setWaiting(false)
    }
    call()
  },[])

  const loadThread = useCallback((thread_id, isEx=false) => {
    return () => {
      console.log('load convo')
      setThreadId(thread_id)
      fetchThread(thread_id)
      saveKey('currentThreadId', thread_id)
      setIsExample(isEx)
      closeList()
    }
  }, [])

  const fetchThreadsMetadata = useCallback(() => {
    async function call () {
      const threadsMeta = await http.get(`/threads`)
      // console.log('fetched threads meta for ', JSON.stringify(threadsMeta))
      saveKey("threadMetadata", JSON.stringify(threadsMeta))
      setThreadMetadata(threadsMeta)
      // console.log('tm', threadMetadata)
    }
    call()
  }, [])

  const fetchThreadsExampleMetadata = useCallback(() => {
    async function call () {
      const threadsMeta = await http.get(`/examples`)
      // console.log('fetched ex meta for ', JSON.stringify(threadsMeta))
      setThreadExampleMetadata(threadsMeta)
    }
    call()
  }, [])


  // *** INIT

  useEffect(() => {
    async function init() {// runs twice only bc React.StrintMode in dev (or some shiiite)
      // network connection
      Network.addListener('networkStatusChange', status => {
        if (!status.connected) return setIsOffline(true)
      });
      const status = await Network.getStatus()
      if (!status.connected) return setIsOffline(true)
      // check auth
      if (!auth) {
        const jwt = await getKey("jwt")
        if (jwt) {
          //ensure session is live
          const ok = await http.get('/sessions')
          // console.log('ok', ok)
          if (ok.status == 200) {
            setAuth(jwt)
          } else {
            setTimeout(() => logout(), 300)
          }
        }
      } else {
        const userRecord = await getKey("user")
        setUser(JSON.parse(userRecord))
        const currentThreadId = await getKey('currentThreadId')
        setThreadId(currentThreadId)
        fetchThread(currentThreadId)
        fetchThreadsMetadata()
        fetchThreadsExampleMetadata()
      }
      // done initializing
      setInitialized(true)
    }
    init()
  }, [auth])

  useEffect(() => {
    if (!appleInfo) return

    async function loadSession () {
      const jwt = await getKey("jwt")
      console.log('load session', jwt)
      if (!jwt) {
        //start session
        console.log('create session', appleInfo, appleInfo.mode)
        await sessionStart()
        const jwt = await getKey("jwt")
        setAuth(jwt)
        const userRecord = await getKey("user")
        setUser(JSON.parse(userRecord))
      }
    }
    loadSession()
  }, [appleInfo])

  useEffect(() => {
    if(!threadId) return // keep current topic for new convo
    const threadMeta = threadMetadata.find(tm => (tm.thread_id == threadId))
    const topic = topics.find(t=>(t.assistantId == threadMeta?.assistant_id)) || topics[0]
    setTopic(topic)
  // console.log('num msg', messages.length)
    setTopicListOpen(false)
  }, [threadId, threadMetadata])

  // *** RENDER

  return (
    <div className="App">
      <Menus props={{isMenuOpen, closeMenu, isListOpen, openList, closeList, threadMetadata, setThreadMetadata, threadExampleMetadata, threadId, loadThread, user, setUser, logout, aboutRefs}}/>

      <OfflineOverlay isOffline={isOffline} />

      {!auth ?
        (initialized && <Login props={{login, loginTest}} />)
      :
        <>
          <header className={`App-header ${isWeb && "App-header-web"}`}>
            <div className='app-header-left'>
              <div className="new-button" onClick={startNewConversation}><img src={plusSvg} alt="new" /></div>
              <div className='list-button' onClick={openList}><img src={stackSvg} alt="list" /></div>
            </div>
            <div className='app-header-right'>
              <div className="menu-button" onClick={openMenu}>
                <img src={settingsSvg} alt="menu" />
                <div className='menu-button-count'><CoinCount count={user?.credit_count || 0} /></div>
              </div>
              <button className='topic' onClick={()=>setTopicListOpen(!topicListOpen)} disabled={topicLocked}>
                <img src={modeSvg} alt="mode" className='topic-icon' />
                {topicLocked
                  ? <img src={lockSvg} alt="lock" className='topic-lock' />
                  : (topicListOpen ? <img src={goSvg} alt="close" className='topic-close' />
                    : <img src={goSvg} alt="open" className='topic-open' />)
                }
                <div>{topic.name}</div>
                {topicListOpen && <div className='topic-list'>
                    <div className='topic-list-header'>Bot Mode:</div>
                    {topics.map((t, i) => {
                      if (t.facilitated) return <></>
                      return <div key={i} className={`topic-item ${t.assistantId == topic.assistantId && 'topic-item-selected'}`} onClick={selectTopic(i)}>
                        {t.name}
                      </div>
                    })}
                  </div>}
              </button>
            </div>
          </header>
          <MessageList props={{messages, waiting, shouldRefresh, showAbout, showSafetyInfo, isExample, topic, currentThread, user, myTurn, theirName}} />
          {!isExample && myTurn &&
            <MessageInput props={{
              messages, setMessages,
              waiting, setWaiting,
              assistantPrompt,
              threadId, setThreadId,
              setThreadMetadata,
              setShouldRefresh,
              user, setUser,
              openMenu,
              topic,
              showConvoInfo,
              currentThread,
              theirName,
              loadThread
            }} />
          }
        </>
      }
    </div>
  );
}

export default App;
