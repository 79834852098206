import './OfflineOverlay.css'
import connectionSvg from './svg/connection.svg'
import connectionNoneSvg from './svg/connection-none.svg'

const reload = () => window.location.reload()

export default ({isOffline}) => {
  return <>
    {isOffline && <>
      <div className="offline-overlay"></div>
      <div className="offline-indicator">
        <div className='offline-header'>
          Offline
          <img src={connectionNoneSvg} alt="wifi" />
        </div>
        <button onClick={reload}>Reload</button>
      </div>
    </>}
  </>
}