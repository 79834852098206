import React, {useRef, useEffect} from 'react';
import './MessageList.css'
import refreshSvg from './svg/refresh.svg'

const MessageList = ({props}) => {
  const {messages, waiting, shouldRefresh, showAbout, showSafetyInfo, isExample, topic, currentThread, user, myTurn, theirName} = props
  const messagesEndRef = useRef(null);

  useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]); 

  const scrollToOffset = waiting ? 1 : 2
  let scrollToIndex = messages.length - scrollToOffset
  if (isExample) scrollToIndex = 0 // start reading example from top

  let isTheirMessage = false //modified mid-map based on most recent directive
  return (
    <div className='message-list-container'>
      {messages.map((message, i) => {// ideally key should be a unique message id
        if(currentThread?.is_debate && message.content.includes("[[[")) {// is directive
          if (message.content.includes("speaking")) {
            if (currentThread.receiver_email == user.email) {
              isTheirMessage = message.content.includes(currentThread.initiator_name)
            } else {
              isTheirMessage = message.content.includes(currentThread.receiver_name)
            }
          }
          return <div ref={i == scrollToIndex ? messagesEndRef : null} key={i} />
        }
        const text = i == 0 ? topic.conversationStarter : message.content
        const isSafetyResponse = text.includes("mental health professional") && (text.includes("I'm unable to provide") || text.includes("I am not equipped"))
        return <div key={i+text}
          className={`message ${message.role == "user" ? `sent-message ${isTheirMessage && 'others-message'}` : 'received-message'}`}
        >
          {i == scrollToIndex && <div ref={messagesEndRef} />}
          {i == 0 && <div className='about-icon' onClick={showAbout}>?</div>}
          {isSafetyResponse && <div className='about-icon' onClick={showSafetyInfo}>!</div>}
          {text}
        </div>
      })}
      {waiting &&
        <div className="ellipsis message received-message">
          {shouldRefresh ?
            <>
              Timed out!
              <div className="refresh" onClick={() => window.location.reload()}>
                <img src={refreshSvg} alt="reload" />
              </div>
            </>
          :
            <>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </>
          }
        </div>
      }
      {!myTurn && <div className='their-turn-indicator'>It's {theirName}'s turn to talk. You will be able to add to the thread when they pass back to you. Check back later, or <span onClick={()=>window.location.reload()}>reload</span> now.</div>}
    </div>
  );
};

export default MessageList;