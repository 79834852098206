import {useState} from 'react'
import appleSvg from './svg/apple.svg'
import './Login.css'
import http from './http.js'
import {saveKey} from './Storage.js'

async function loginWithCode() {
  const email = document.getElementById("emailInput").value
  const code = document.getElementById("codeInput").value
  const {sessionToken, userRecord} = await http.post('/sessions', {type: "code", code, email})
  console.log('session start', sessionToken, userRecord)
  await saveKey("jwt", sessionToken)
  await saveKey("user", JSON.stringify(userRecord))
  window.location.reload()
}

function validateEmail(email) {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return regex.test(email)
}

export default ({props}) => {
  const {login, loginTest} = props
  const platform = process.env.REACT_APP_DEVICE

  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [code, setCode] = useState('')
  const [codeValid, setCodeValid] = useState(false)
  const [codeSent, setCodeSent] = useState(false)

  const changeEmail = (e) => {
    const email = e.target.value
    const valid = validateEmail(email)
    setEmail(email)
    setEmailValid(valid)
    setCodeSent(false)
  }

  const changeCode = (e) => {
    const code = e.target.value
    const valid = code.length == 8
    setCode(code)
    setCodeValid(valid)
  }

  const requestLoginCode = () => {
    const email = document.getElementById("emailInput").value
    console.log("req code for", email)
    http.post('/codes', {email})
    setCodeSent(true)
  }

  return <>
    <div className='login-header'>
      <div className='login-subtitle'>AI Life-Coach / Mentor</div>
      <div className='login-title'>CyberMonk</div>
    </div>
    <div className='login-body'></div>
    <div className='login-footer'>
      {platform == "ios" ?
        <button className='login-apple' onClick={login}>
          <img src={appleSvg} alt="login" />
          <span>Login with Apple</span>
        </button>
      :
        (platform == "android" ?
          <button className='login-test-btn' onClick={loginTest}>Login Test Android</button>
        :
          <div className='code-login-container'>
            <input id="emailInput" type="email" placeholder='email' onChange={changeEmail} value={email} />
            <button onClick={requestLoginCode} disabled={!emailValid || codeSent}>
              Send Code
              {codeSent && <div>Sent!</div>}
            </button>
            <br/>
            <input id="codeInput" placeholder='code (from email)' onChange={changeCode} value={code} />
            <button onClick={loginWithCode} disabled={!codeValid || !emailValid}>Login</button>
          </div>
        )
      }
      
      {/* <button onClick={checkLocation}>check</button> */}
      <div className="policies">
        By creating an account you are agreeing to the
        <a href="https://drive.google.com/file/d/1NeMIH0wCGva7GXgM8IIkx_YEau9rp-pk/view?usp=drive_link" target='_blank'>terms</a>
        and
        <a href="https://drive.google.com/file/d/1DUTS3ubROYAGxwief5hEG1noRGtkGUwd/view?usp=sharing" target='_blank'>privacy</a>
        policies.
      </div>
    </div>
  </>
}

//for determining api domain on iOS. used in http.js

// function checkLocation () {
//   console.log('location', window.location, window.location.hostname)
//   async function checkCreds () {
//     const jwt = await getKey('jwt')
//     const appleInfo = await getKey('appleAuthObj')
//     console.log('creds', jwt, appleInfo)
//   }
//   checkCreds()
// }